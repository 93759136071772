import $ from "jquery";
import "slick-carousel";
import React from "react";
import ReactDOM from "react-dom";

import { PREVIEW_MAIN } from "../constants/Misc";
import ProductCard, { lazyLoadProductCardImages } from "./productCard";
import Recommendations from "./recommendations";
import { setHeaderColor } from "./setHeaderTheme";
import { VideoLink } from "./videoBanner";

class Container {
  constructor() {
    this.initBannerSlider();
    this.initFullWidthSlider();
    this.intiVideo();
    this.initTimelineCarousel();
    this.initRecommendedProducts();
    this.initOldRecommendations();

    new ProductCard();
    lazyLoadProductCardImages();

    $(".js-header-with-collapsible-body-container__dropdown-trigger").on(
      "click",
      this.handleClickDropdownTrigger.bind(this)
    );
    // .js-banner-url click redirect
    $(".js-banner-url").on("click", this.handleClickBanner.bind(this));

    $(".js-recommended-products-container").on(
      "click",
      ".js-recommended-products-container__tab",
      this.handleClickTab.bind(this)
    );
  }

  initBannerSlider() {
    if ($(".js-pagebuilder-slider").length === 0) return;
    $(".js-pagebuilder-slider").each(function () {
      const isMobileOneColumnSlider =
        $(this).data("mobile-one-column-slider") === true;

      const columnNumber = $(this).data("column-number");

      const $chevronLeft = `
      <button
        class="chevron chevron-left left-rounded-arrow"
        type="button"
      ></button>`;

      const $chevronRight = `
      <button
        class="chevron chevron-next right-rounded-arrow"
        type="button"
      ></button>`;

      const childrenLength = $(this).children("div").length;
      if (
        childrenLength > columnNumber ||
        (isMobileOneColumnSlider && childrenLength > 1)
      ) {
        $(this).before($chevronLeft);
        $(this).after($chevronRight);

        $(this).slick({
          infinite: false,
          slidesToShow: columnNumber,
          slidesToScroll: columnNumber,
          prevArrow: $(this).siblings(".left-rounded-arrow"),
          nextArrow: $(this).siblings(".right-rounded-arrow"),
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
              },
            },
          ],
        });
      }
    });
  }

  initTimelineCarousel() {
    if ($(".js-timeline-carousel-slider").length === 0) return;

    $(".js-timeline-carousel-slider").each(function () {
      $(this).slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $(this).siblings(".left-rounded-arrow"),
        nextArrow: $(this).siblings(".right-rounded-arrow"),
      });
    });
  }

  initFullWidthSlider() {
    if ($(".js-full-width-slider").length === 0) return;

    const $normalHeroBannerSlider = $(
      "#header-container + .js-full-width-slider"
    );
    const $pagebuilderHeroBannerSlider = $(
      "#header-container + .pagebuilder-containers > .full-width-slider:first-child"
    );

    const $heroBannerSlider = $normalHeroBannerSlider.length
      ? $normalHeroBannerSlider
      : $pagebuilderHeroBannerSlider;

    $heroBannerSlider.on("init beforeChange", function () {
      let $nextSlide;

      // Use beforeChange event to change the color before changing to avoid the delay.
      // beforeChange
      // Arguments: event, slick, currentSlide, nextSlide
      const nextSlideIndex = arguments[3];
      if (nextSlideIndex != null) {
        $nextSlide = $heroBannerSlider.find(
          `.slick-slide[data-slick-index="${nextSlideIndex}"]`
        );
      } else {
        $nextSlide = $heroBannerSlider.find(".slick-active");
      }

      const isWhite = $nextSlide.data("header-color") === "White";

      setHeaderColor($heroBannerSlider, isWhite);

      $heroBannerSlider
        .find(".slick-dots")
        .toggleClass("slick-dots--white", isWhite);
    });

    $(".js-full-width-slider").slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 600,
      slide: ".full-width-slider--slide, .js-banner-url",
      rows: 0, // https://stackoverflow.com/questions/30036742/jquery-slick-carousel-slide-setting/57620688#57620688
    });
  }

  handleClickBanner(event) {
    // Prevent banner link on pagebuilder preview page
    if ($(PREVIEW_MAIN).length !== 0) return;

    const $target = $(event.currentTarget);
    const url = $target.data("url");
    if (url) {
      window.location.href = url;
    }
  }

  intiVideo() {
    if ($(".js-pagebuilder-video").length === 0) return;

    $(".js-pagebuilder-video").each(function () {
      const width = $(this).data("width");
      const height = $(this).data("height");

      let bottomPadding = 0;
      if (typeof width === "number" && typeof height === "number") {
        bottomPadding = Number((height / width) * 100).toFixed(3) + "%";
      }

      const videoUrl = $(this).data("url");

      const domElement = $(this)[0];
      if (bottomPadding) {
      }

      if (domElement)
        ReactDOM.render(
          <div
            style={bottomPadding ? { "padding-bottom:": bottomPadding } : {}}
          >
            <VideoLink videoUrl={videoUrl} autoplay={true} mute={true} />
          </div>,
          domElement
        );
    });

    const topVideo = $(
      "#header-container + .js-pagebuilder-video, #header-container + .pagebuilder-containers > .js-pagebuilder-video:first-child"
    );

    if (topVideo.length > 0) {
      const isWhite = topVideo.data("header-color") === "White";
      setHeaderColor(topVideo, isWhite);
    }
  }

  handleClickDropdownTrigger(event) {
    const $target = $(event.currentTarget);
    const $list = $target.parent().siblings(".js-rich-editor-content");
    $target.toggleClass("is-active");
    if ($target.hasClass("is-active")) {
      $list.slideDown(150);
      $target.text("閉じる");
    } else {
      $list.slideUp(150);
      $target.text("詳細を見る");
    }
  }

  initRecommendedProducts() {
    if ($(".js-recommended-products-container__products-slider").length === 0)
      return;

    $(".js-recommended-products-container__products-slider").each(function () {
      $(this).slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: $(this).siblings(".left-rounded-arrow"),
        nextArrow: $(this).siblings(".right-rounded-arrow"),
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.2,
            },
          },
        ],
      });
    });
  }

  initOldRecommendations() {
    if ($(".recommendations").length === 0) return;

    new Recommendations();
  }

  handleClickTab(event) {
    const $target = $(event.currentTarget);

    if ($target.hasClass("is-active")) return;

    const $container = $target.closest(".js-recommended-products-container");

    $container
      .find(".js-recommended-products-container__tab")
      .removeClass("is-active");
    $target.addClass("is-active");

    const activeIndex = $container
      .find(".swiper-wrapper")
      .find(".swiper-slide")
      .index($target.closest(".swiper-slide"));

    const $productsPanel = $container.find(
      ".js-recommended-products-container__products-panel"
    );
    $productsPanel
      .find(".js-recommended-products-container__products-slider")
      .removeClass("is-active");
    $productsPanel
      .find(".js-recommended-products-container__products-slider")
      .eq(activeIndex)
      .addClass("is-active");
  }
}

export default Container;
